export enum AnimationTrigger {
  // Idle = "Idle",
  // Shoot = "Shoot",
  // Tackle = "Tackle",
  // Celebrate = "Celebrate",
  // Dizzy = "Dizzy",
  // PassLeft = "PassLeft",
  // Sad = "Sad",
  // PowerUp = "PowerUp",

  Blocking = "CharacterRig|Blocking",
  Celebrate = "CharacterRig|Celebrate",
  Charging = "CharacterRig|Charging",
  Dizzy = "CharacterRig|Dizzy",
  Idle = "CharacterRig|Idle",
  IdleLeft = "CharacterRig|IdleLeft",
  IdleRight = "CharacterRig|IdleRight",
  Pass = "CharacterRig|Pass",
  PowerUp = "CharacterRig|PowerUp",
  Run = "CharacterRig|Run",
  RunLeft = "CharacterRig|RunLeft",
  RunRight = "CharacterRig|RunRight",
  Sad = "CharacterRig|Sad",
  Shoot = "CharacterRig|Shoot",
  Stealing = "CharacterRig|Stealing",
  Tackle = "CharacterRig|Tackle",
  Walk = "CharacterRig|Walk",
  WalkLeft = "CharacterRig|WalkLeft",
  WalkRight = "CharacterRig|WalkRight",
}

export type Vector3XYZ = {
  x: number;
  y: number;
  z: number;
};

export type RecordedEvent = {
  normal?: Vector3XYZ;
  teamA?: boolean | number;
  teamB?: number;
  isSuperShot?: boolean;
  isRunning?: boolean;
  animTrigger: keyof typeof AnimationTrigger;
  isUp?: boolean;
  rotation?: number;
  position?: Vector3XYZ;
  playerIndex?: number;
  pos?: Vector3XYZ;
};
