/* eslint-disable react/no-unknown-property */
import { useCallback, useRef, useState } from "react";
import { Vector3 } from "three";
import { useControls } from "leva";

import { Html, useAnimations, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { AnimationTrigger, RecordedEvent } from "./RecordedEvent";
import { updateScore } from "./gameEvents";
import { generateDomeImpactFx, generateGoalFx } from "./replayFX";
import { Player } from "./Player";
import { Ball } from "./Ball";
import { DomeHit } from "./DomeHit";
import { generateUUID } from "three/src/math/MathUtils";

type PlayerState = {
  position: Vector3;
  rotation: number;
  animationTrigger?: AnimationTrigger;
};
type BallState = {
  ownerIndex?: number;
  position: Vector3;
  isSuperShot: boolean;
};
type DomeHitProp = { normal: Vector3; position: Vector3; uid: string };

export type GameState = {
  currentFrame: number;
  players: PlayerState[];
  ball: BallState;
  timeScale: number;
  pastEvents: { players: { frame: number; eventType?: AnimationTrigger }[] };
};

type ReplayData = {
  metadata: any;
  recordedFrames: [
    {
      events: RecordedEvent[];
    }
  ];
};

type ModelProps = {
  stadiumPath: string;
  replayData: ReplayData;
};
const FPS = 60;
const initialState: GameState = {
  currentFrame: 0,
  timeScale: 1,
  players: [
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
    { position: new Vector3(), rotation: 0 },
  ],
  pastEvents: {
    players: [
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
      { frame: 0 },
    ],
  },
  ball: { position: new Vector3(), isSuperShot: false },
};

const players = [
  {
    name: "player 1",
    modelPath: "./forms/0afe41b8bf7af72acc87cc94ff155684.glb",
    team: "teamA",
  },
  {
    name: "player 2",
    modelPath: "./forms/0d05d97c0601dd01e3acba29545ae01b.glb",
    team: "teamA",
  },
  {
    name: "player 3",
    modelPath: "./forms/2ef00ff7c06b98c5d9d85ebc0c3f37e7.glb",
    team: "teamA",
  },
  {
    name: "player 4",
    modelPath: "./forms/10d4a43372e5c1001dc91bbe6ea0b081.glb",
    team: "teamA",
  },
  {
    name: "player 5",
    modelPath: "./forms/78cc0fff55a3ad1164c0662d62c510d3.glb",
    team: "teamB",
  },
  {
    name: "player 6",
    modelPath: "./forms/152b75c72213a0e951e429761250b274.glb",
    team: "teamB",
  },
  {
    name: "player 7",
    modelPath: "./forms/44776f6b5c2096981fff78d09f764c59.glb",
    team: "teamB",
  },
  {
    name: "player 8",
    modelPath: "./forms/72830d945c452bbd49fff8f16975d95f.glb",
    team: "teamB",
  },
];

export const ReplayModel = ({ stadiumPath, replayData }: ModelProps) => {
  const gameState = useRef<GameState>(initialState);
  const { nodes } = useGLTF(stadiumPath);
  console.log("🚀 ~ nodes", nodes);

  const [domeImpacts, setDomeImpacts] = useState<DomeHitProp[]>([]);

  // we just want a global mixer to scale the time with
  const { mixer } = useAnimations([]);
  const [{ gameSpeed, paused, frame, showState }, set] = useControls(() => ({
    gameSpeed: {
      value: 1,
      min: 0,
      max: 10,
      step: 0.1,
    },
    showState: { value: false },
    paused: { value: false },
    frame: {
      value: 0,
      min: 0,
      max: replayData.recordedFrames.length - 1,
      step: 1,
      // render: (get) => get("paused"),
    },
  }));

  useFrame(() => {
    const maxFrame = replayData.recordedFrames.length - 1;

    if (paused) {
      mixer.timeScale = 0;
      gameState.current.currentFrame = Math.min(maxFrame, frame);
    } else {
      mixer.timeScale = gameSpeed;
      const targetFrame = Math.min(maxFrame, Math.floor(mixer.time * FPS));
      set({ frame: targetFrame });
      gameState.current.currentFrame = targetFrame;
    }
    gameState.current.timeScale = mixer.timeScale;
    processFrameEvents(
      replayData.recordedFrames[gameState.current.currentFrame].events
    );
  });

  const updateReplayBall = (recordedFrameEvents: RecordedEvent[]) => {
    const ballFromState = gameState.current.ball;
    // TODO: instead of having the logic in here, I think we want to process the incoming JSON and shape it so it matches our gameState structure
    // e.g remove the dependency of "ownerIndex" and just always have the correct position in the JSON

    recordedFrameEvents.forEach((e) => {
      if (Object.keys(e).length == 1) {
        ballFromState.ownerIndex = undefined;
        if (e.pos) {
          ballFromState.position.set(e.pos.x, e.pos.y, e.pos.z);
        }
        if (e.playerIndex || e.playerIndex === 0) {
          // Ball ownership determins the ball position in relation to the owner
          if (e.playerIndex === -1) {
            ballFromState.ownerIndex = undefined;
          } else {
            ballFromState.ownerIndex = e.playerIndex;
          }
        }
        ballFromState.isSuperShot = !!e.isSuperShot;
      }
      if (e.position && e.normal) {
        setDomeImpacts([
          ...domeImpacts,
          {
            uid: generateUUID(),
            normal: new Vector3(e.normal.x, e.normal.y, e.normal.z),
            position: new Vector3(e.position.x, e.position.y, e.position.z),
          },
        ]);

        generateDomeImpactFx(e.position, e.normal);
      }
    });
  };
  const updateReplayPlayers = (recordedFrameEvents: RecordedEvent[]) => {
    recordedFrameEvents.forEach((e) => {
      if ((e.playerIndex || e.playerIndex === 0) && e.playerIndex !== -1) {
        if (Object.keys(e).length > 1) {
          if (e.playerIndex < players.length) {
            const playerFromState = gameState.current.players[e.playerIndex];
            if (e.position !== undefined && e.rotation !== undefined) {
              playerFromState.position = new Vector3(
                e.position.x,
                e.position.y,
                -e.position.z
              );
              playerFromState.rotation =
                // this is from the unity function ByteToDegrees, @James Lambourn
                e.rotation * Math.PI * (180 / Math.PI) * 0.01;
            }

            const animation = e.isRunning
              ? AnimationTrigger.Run
              : AnimationTrigger[e.animTrigger];

            if (e.animTrigger) {
              // Debug purpose only
              gameState.current.pastEvents.players[e.playerIndex].frame =
                gameState.current.currentFrame;
              gameState.current.pastEvents.players[e.playerIndex].eventType =
                animation;
            }
            playerFromState.animationTrigger = animation;
            if (e.isUp !== undefined) {
              // For scaling the character up (superSize event)
              // _player.isUp = e.isUp;
            }
            if (e.pos) {
              // teleport fx event
              // _player.teleportPos = e.pos;
            }
            // _player.update();
          }
        }
      }
    });
  };
  const updateGameState = (recordedFrameEvents: RecordedEvent[]) => {
    recordedFrameEvents.forEach((e) => {
      if (e.teamA) {
        if (typeof e.teamA == "boolean") {
          generateGoalFx(e.teamA ? "teamA" : "teamB");
        } else if (e.teamA && typeof e.teamA == "number" && e.teamB) {
          updateScore(e.teamA, e.teamB);
        }
      }
    });
  };
  const processFrameEvents = (recordedFrameEvents: RecordedEvent[]) => {
    // TODO:  Main down side with this is its processing the recordedFrameEvents 3 times.
    //        Ideally update to process all events once to update objects.

    // Process player events
    updateReplayPlayers(recordedFrameEvents);

    // Process ball events
    updateReplayBall(recordedFrameEvents);

    // Process game events
    updateGameState(recordedFrameEvents);
  };

  // we don't want this updated every render!!!
  const removeImpact = useCallback((orginalUID: string) => {
    setDomeImpacts((current) =>
      current.filter(({ uid }) => uid !== orginalUID)
    );
  }, []);

  return (
    <>
      {showState && (
        <>
          <Html>
            <span className="warning">
              WARNING: when stepping frames, this isn't auto-updated! toggle the
              debug panel to force a re-render
            </span>
            <div className="game-states">
              <pre className="game-state">
                <h1>
                  gamestate @frame {gameState.current.currentFrame} || {frame}
                </h1>
                <code>{JSON.stringify(gameState.current, null, 2)}</code>
              </pre>
              <pre className="game-state game-state--original">
                <h1>
                  replayJSON @frame {gameState.current.currentFrame} || {frame}
                </h1>
                <code>
                  {JSON.stringify(
                    replayData.recordedFrames[gameState.current.currentFrame]
                      .events,
                    null,
                    2
                  )}
                </code>
              </pre>
            </div>
          </Html>
        </>
      )}
      {/* Stadium */}
      <primitive object={nodes.Scene} scale={3} receiveShadow={true} />
      {players.map(({ modelPath }, index) => {
        return (
          <Player
            key={index}
            playerIndex={index}
            gameState={gameState}
            path={modelPath}
          />
        );
      })}
      <Ball gameState={gameState} />
      {domeImpacts.map((impact) => {
        return (
          <DomeHit
            key={impact.uid}
            uid={impact.uid}
            onComplete={removeImpact}
            position={impact.position}
            normal={impact.normal}
          />
        );
      })}
    </>
  );
};
