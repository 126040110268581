import React, { MutableRefObject } from "react";
import { Group, InstancedMesh, Mesh, Object3D } from "three";

import { Sphere, useTrail } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { GameState } from "./ReplayModel";

type ModelProps = {
  // We can narrow the state down to be just PlayerState in the future
  gameState: MutableRefObject<GameState>;
};

export const Ball = ({ gameState }: ModelProps) => {
  const [ball, setBall] = React.useState<Mesh>(null!);
  const instancesRef = React.useRef<InstancedMesh>(null!);

  const trailPositions = useTrail(ball, {
    length: 5,
    decay: 10,
    interval: 4,
  });
  const n = 1000;

  const [o] = React.useState(() => new Object3D());
  const updateInstances = () => {
    // https://drei.pmnd.rs/?path=/story/misc-trail--use-trail-st
    if (!instancesRef.current) return;
    if (!trailPositions.current) return;

    for (let i = 0; i < n; i += 1) {
      const x = trailPositions.current.slice(i * 3, i * 3 + 3);
      // @ts-ignore
      o.position.set(...x);

      o.scale.setScalar((i * 20) / n);
      o.updateMatrixWorld();

      instancesRef.current.setMatrixAt(i, o.matrixWorld);
    }

    instancesRef.current.count = n;
    instancesRef.current.instanceMatrix.needsUpdate = true;
  };

  useFrame(({ clock }, delta) => {
    const currentBallState = gameState.current.ball;
    ball.position.copy(currentBallState.position);
    if (currentBallState.ownerIndex === undefined) {
      ball.position.copy(currentBallState.position);
    } else {
      const currentOwnerState =
        gameState.current.players[currentBallState.ownerIndex];
      ball.position.copy(currentOwnerState.position);
    }

    updateInstances();
  });

  return (
    <>
      <group position={[0, 0.5, 0]}>
        <Sphere ref={setBall} args={[0.5, 32, 32]}>
          <meshStandardMaterial color="orange" />
        </Sphere>
        {/* we need to negatively off set it to match the orginal group for some reason */}
        <group position={[0, -0.5, 0]}>
          <instancedMesh ref={instancesRef} args={[undefined, undefined, n]}>
            <boxGeometry args={[0.5, 0.5, 0.5]} />
            {/* <meshNormalMaterial /> */}
            <meshStandardMaterial color="orangered" />
          </instancedMesh>
        </group>
      </group>
    </>
  );
};

// useGLTF.preload(modelPath);
