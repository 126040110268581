import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { AxesHelper, CameraHelper, Group, LoopOnce } from "three";

import { Box, useAnimations, useHelper } from "@react-three/drei";
import { applyProps, useFrame } from "@react-three/fiber";
import { GameState } from "./ReplayModel";
import { useSkinnedMeshClone } from "../AllStar/AllStarModel";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";
import { AnimationTrigger } from "./RecordedEvent";

type ModelProps = {
  path: string;
  // We can narrow the state down to be just PlayerState in the future
  gameState: MutableRefObject<GameState>;
  playerIndex: number;
};

export const Player = ({ playerIndex, gameState, path }: ModelProps) => {
  const { nodes, animations } = useSkinnedMeshClone(path);
  const positionGroupRef = React.useRef<Group>(null);
  const allStarRef = React.useRef<Group>(null);
  const [animTrigger, setAnimTrigger] = useState<AnimationTrigger>();
  //  gameState.current.players[playerIndex].animationTrigger;

  const { actions, mixer } = useAnimations(animations, allStarRef);
  //   // TODO: check https://codesandbox.io/s/pecl6 for fadeIn/Out of animations

  useEffect(() => {
    // Reset and fade in animation after an index has been changed

    if (animTrigger) {
      if (animTrigger === AnimationTrigger.Run) {
        actions[animTrigger]?.reset().fadeIn(0.2).play();
      } else {
        actions[animTrigger]?.reset().fadeIn(0.5).setLoop(LoopOnce, 1).play();
      }
    } else {
      // actions[AnimationTrigger.Idle]?.reset().fadeIn(0.5).play();
    }
    return () => {
      // console.log(d);
      if (animTrigger) actions[animTrigger]?.fadeOut(0.5);
    };
  }, [actions, animTrigger]);

  // useEffect(() => {
  //   actions[AnimationTrigger.Tackle]?.reset().fadeIn(0.5).play();
  // }, [actions]);

  useLayoutEffect(() => {
    // enable the shadows, they might not be present in the blender export?
    nodes.Scene.traverse((obj) => {
      // HACKERDYHACK
      const o = obj as unknown as Instance;
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.2,
        });
      }
    });
  }, [nodes.Scene]);

  useFrame(({ clock }, delta) => {
    const currentPlayerState = gameState.current.players[playerIndex];
    // console.log(
    //   "🚀 animation for player,",
    //   playerIndex,
    //   currentPlayerState.animationTrigger
    // );
    mixer.timeScale = gameState.current.timeScale;

    if (animTrigger !== currentPlayerState.animationTrigger) {
      setAnimTrigger(currentPlayerState.animationTrigger);
    }

    positionGroupRef.current?.position.copy(currentPlayerState.position);

    positionGroupRef.current?.rotation.set(
      0,
      // the unity rotation is diff than the threejs
      (360 - currentPlayerState.rotation - 180) * (Math.PI / 180),
      // currentPlayerState.rotation * (Math.PI / 180) + 45,
      0
    );
  });

  return (
    <>
      <group ref={positionGroupRef}>
        <axesHelper args={[3]} />
        <primitive
          ref={allStarRef}
          object={nodes.Scene}
          scale={0.85}
          castShadow={true}
          receiveShadow={true}
        />
        {/* <Box position={[0, 0.5, 0]}>
          <meshStandardMaterial color="blue" />
        </Box> */}
      </group>
    </>
  );
};

// useGLTF.preload(modelPath);
