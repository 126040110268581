import { Suspense } from "react";

import { ReplayModel } from "./ReplayModel";

type Props = {
  stadium: string;
  replayData: any;
};

export const Replay = ({ stadium: replayPath, replayData }: Props) => {
  return (
    <group castShadow>
      <Suspense fallback={null}>
        <ReplayModel stadiumPath={replayPath} replayData={replayData} />
      </Suspense>
    </group>
  );
};
